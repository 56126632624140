// tslint:disable:max-line-length
import { RedirectFunction, RouterState } from 'react-router';
import App from './../app.module';
import { ErrorPage } from './../pages/error.page';
import { NoMatchPage } from './../pages/no-match.page';
import { IAppState } from './../redux/interfaces/IAppState';
import { isLoaded, load } from './../redux/modules/auth.module';

const isBrowser = typeof window !== 'undefined';
// Polyfill
if (typeof require.ensure !== 'function')
    require.ensure = (d, c) => {
        c(require);
    };

export default store => {
    function isAuthenticated(state: IAppState): boolean {
        return state.auth && state.auth.user && !!state.auth.user.id;
    }

    function requireAuth(nextState: RouterState, replace: RedirectFunction, callback: Function) {
        const { dispatch, getState } = store;
        const state = getState() as IAppState;
        const redirect = () => {
            replace({
                pathname: '/auth/login',
                state: { nextPathname: nextState.location.pathname }
            });
        };

        if (isAuthenticated(state)) return callback();

        if (!isBrowser || isLoaded(state)) {
            redirect();
            return callback();
        }

        dispatch(load()).then(user => {
            if (!isAuthenticated({ auth: { user } } as IAppState)) {
                redirect();
            }
            callback();
        });
    }

    return {
        path: '/',
        // tslint:disable-next-line:object-literal-sort-keys
        component: App,
        indexRoute: {
            onEnter: requireAuth,
            // tslint:disable:object-literal-shorthand
            // tslint:disable:only-arrow-functions
            getComponent(location: any, cb: any) {
                require.ensure(
                    [],
                    function() {
                        cb(null, require('./../pages/home/home.page').default);
                    },
                    'home.page'
                );
            }
        },
        childRoutes: [
            {
                path: 'auth/login',
                getComponent(location: any, cb: any) {
                    require.ensure(
                        [],
                        function() {
                            cb(null, require('./../pages/auth/login.page').default);
                        },
                        'login.page'
                    );
                },
                hideBackButton: true
            },
            // {
            //     path: 'auth/register', getComponent(location: any, cb: any) {
            //         require.ensure([], function () {
            //             cb(null, require('./../pages/auth/register.page').default);
            //         });
            //     }
            // },
            {
                path: 'auth/forgot',
                getComponent(location: any, cb: any) {
                    require.ensure(
                        [],
                        function() {
                            cb(null, require('./../pages/auth/forgot.page').default);
                        },
                        'forgot.page'
                    );
                }
            },
            {
                path: 'notifications',
                onEnter: requireAuth,
                getComponent(location: any, cb: any) {
                    require.ensure(
                        [],
                        function() {
                            cb(null, require('./../pages/notification/notifications.page').default);
                        },
                        'notifications.page'
                    );
                }
            },
            {
                path: 'transmitters',
                onEnter: requireAuth,
                indexRoute: {
                    getComponent(location: any, cb: any) {
                        require.ensure(
                            [],
                            function() {
                                cb(null, require('./../pages/transmitter/transmitters.page').default);
                            },
                            'transmitters.page'
                        );
                    }
                },
                childRoutes: [
                    {
                        path: 'create',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/transmitter/transmitter-create.page').default);
                                },
                                'transmitter-create.page'
                            );
                        }
                    },
                    {
                        path: 'detail/:transmitterId',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/transmitter/transmitter.page').default);
                                },
                                'transmitter.page'
                            );
                        }
                    },
                    {
                        path: 'update/:transmitterId',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/transmitter/transmitter-update.page').default);
                                },
                                'transmitter-update.page'
                            );
                        }
                    },
                    {
                        path: 'dashboard/:transmitterId',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/transmitter/transmitter-dashboard.page').default);
                                },
                                'transmitter-dashboard.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/processes',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/process/processes.page').default);
                                },
                                'processes.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/processes/upload',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/process/process-upload.page').default);
                                },
                                'process-upload.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/processes/:processId',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/process/process.page').default);
                                },
                                'process.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/processes/:processId/export',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/process/export-process.page').default);
                                },
                                'export-process.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/monitoring-informations',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(
                                        null,
                                        require('./../pages/monitoring-information/monitoring-informations.page')
                                            .default
                                    );
                                },
                                'monitoring-informations.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/requests',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/request/requests.page').default);
                                },
                                'requests.page'
                            );
                        }
                    },
                    {
                        path: ':transmitterId/requests/:requestId',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/request/request.page').default);
                                },
                                'request.page'
                            );
                        }
                    }
                ]
            },
            {
                path: 'yandex-o-auth',
                onEnter: requireAuth,
                getComponent(location: any, cb: any) {
                    require.ensure(
                        [],
                        () => {
                            cb(null, require('./../pages/yandex-o-auth/yandex-o-auth.page').default);
                        },
                        'yandex-o-auth-page'
                    );
                }
            },
            {
                path: 'firmwares',
                onEnter: requireAuth,
                indexRoute: {
                    getComponent(location: any, cb: any) {
                        require.ensure(
                            [],
                            function() {
                                cb(null, require('./../pages/firmware/firmwares.page').default);
                            },
                            'firmwares.page'
                        );
                    }
                },
                childRoutes: [
                    {
                        path: 'create',
                        onEnter: requireAuth,
                        getComponent(location: any, cb: any) {
                            require.ensure(
                                [],
                                function() {
                                    cb(null, require('./../pages/firmware/firmware-create.page').default);
                                },
                                'firmware-create.page'
                            );
                        }
                    }
                ]
            },
            {
                path: 'account',
                onEnter: requireAuth,
                getComponent(location: any, cb: any) {
                    require.ensure(
                        [],
                        function() {
                            cb(null, require('./../pages/account/account.page').default);
                        },
                        'account.page'
                    );
                }
            },
            { path: 'error', component: ErrorPage },
            { path: '*', component: NoMatchPage }
        ]
    };
};
