// tslint:disable:max-line-length
import { AxiosRequestConfig } from 'axios';
import { IPageFilter } from './../../../interfaces/IPageFilter.d';
import { ITransmitter } from './../../../models/transmitter.model';
import { ApiClient } from './../../helpers/api-client.helper';
import { IAppState } from './../interfaces/IAppState.d';

const model = `transmitter`;
const QUERY = `${model}/QUERY`;
const QUERY_SUCCESS = `${model}/QUERY_SUCCESS`;
const QUERY_FAIL = `${model}/QUERY_FAIL`;
const GET = `${model}/GET`;
const GET_SUCCESS = `${model}/GET_SUCCESS`;
const GET_FAIL = `${model}/GET_FAIL`;
const UPDATE = `${model}/UPDATE`;
const UPDATE_SUCCESS = `${model}/UPDATE_SUCCESS`;
const UPDATE_FAIL = `${model}/UPDATE_FAIL`;
const SAVE = `${model}/SAVE`;
const SAVE_SUCCESS = `${model}/SAVE_SUCCESS`;
const SAVE_FAIL = `${model}/SAVE_FAIL`;
const DELETE = `${model}/DELETE`;
const DELETE_SUCCESS = `${model}/DELETE_SUCCESS`;
const DELETE_FAIL = `${model}/DELETE_FAIL`;
const CHANGE = `${model}/CHANGE_${model}`;
const CHANGE_FILTER = `${model}/CHANGE_FILTER`;

const API_URL = '/api/v1/transmitters';

export function isLoaded(globalState: IAppState): boolean {
    return globalState.transmitter && globalState.transmitter.dataLoadedSuccess;
}

export function query(options: AxiosRequestConfig) {
    return {
        types: [QUERY, QUERY_SUCCESS, QUERY_FAIL],
        promise: (client: ApiClient<IQueryResult<ITransmitter>>) => client.get(API_URL, options)
    };
}

export function get(id: string): IAction {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        promise: (client: ApiClient<ITransmitter>) => client.get(`${API_URL}/${id}`),
        id
    } as IAction;
}

export function update(data: ITransmitter): IAction {
    return {
        types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
        promise: (client: ApiClient<ITransmitter>) => client.put(`${API_URL}/${data.id}`, data),
        id: data.id
    } as IAction;
}

export function save(data: ITransmitter): IAction {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client: ApiClient<ITransmitter>) => client.post(API_URL, data)
    } as IAction;
}

export function remove(id: string): IAction {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: (client: ApiClient<ITransmitter>) => client.delete(`${API_URL}/${id}`)
    } as IAction;
}

export function change(order: ITransmitter): IAction {
    return {
        type: CHANGE,
        result: order,
        id: order.id
    } as IAction;
}

export function changeFilter(filter: any): IAction {
    return {
        type: CHANGE_FILTER,
        result: filter
    } as IAction;
}

export interface ITransmitterState
    extends IReadProps,
        ICreateProps,
        IUpdateProps,
        IDeleteProps,
        IQueryProps<ITransmitter> {
    filter: IPageFilter;
    transmitter: ITransmitter;
    id: string;
}

const initialState = {
    filter: {
        limit: 10,
        page: 1
    },
    dataLoadedSuccess: false,
    transmitter: {} as ITransmitter
} as ITransmitterState;

interface ITransmitterAction extends IAction {
    id: number;
}

const defaultAction: ITransmitterAction = { type: '', result: null, error: null, id: null } as ITransmitterAction;

export default (
    state: ITransmitterState = initialState,
    action: ITransmitterAction = defaultAction
): ITransmitterState => {
    switch (action.type) {
        case QUERY:
            return { ...state, dataLoading: true };
        case QUERY_SUCCESS:
            return { ...state, dataLoading: false, dataLoadedSuccess: true, data: action.result };
        case QUERY_FAIL:
            return { ...state, dataLoading: false, dataLoadedSuccess: false, dataLoadedError: action.error };
        case GET:
            return { ...state, getting: true };
        case GET_SUCCESS:
            return { ...state, getting: false, [action.id]: action.result, getError: '' };
        case GET_FAIL:
            return { ...state, getting: false, [action.id]: null, getError: action.error };
        case UPDATE:
            return { ...state, updating: true };
        case UPDATE_SUCCESS:
            return { ...state, updating: false, updateSuccess: true, updateError: '' };
        case UPDATE_FAIL:
            return { ...state, updating: false, updateSuccess: false, updateError: action.error };
        case SAVE:
            return { ...state, saving: true };
        case SAVE_SUCCESS:
            return { ...state, saving: false, saveSuccess: true, id: action.result.id, [action.id]: null };
        case SAVE_FAIL:
            return { ...state, saving: false, saveSuccess: false, saveError: action.error };
        case DELETE:
            return { ...state, deleting: true };
        case DELETE_SUCCESS:
            return { ...state, deleting: false, deleteSuccess: true };
        case DELETE_FAIL:
            return { ...state, deleting: false, deleteSuccess: false, deleteError: action.error };
        case CHANGE:
            return { ...state, [action.id]: action.result };
        case CHANGE_FILTER:
            return { ...state, filter: action.result };

        default:
            return state;
    }
};
