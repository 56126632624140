import { AxiosRequestConfig } from 'axios';
import { IYandexDiskResource } from './../../../interfaces/yandex-disk.api.d';
import { ApiClient } from './../../helpers/api-client.helper';
import { IAppState } from './../interfaces/IAppState.d';

const model = `firmware`;
const QUERY = `${model}/QUERY`;
const QUERY_SUCCESS = `${model}/QUERY_SUCCESS`;
const QUERY_FAIL = `${model}/QUERY_FAIL`;
const GET = `${model}/GET`;
const GET_SUCCESS = `${model}/GET_SUCCESS`;
const GET_FAIL = `${model}/GET_FAIL`;
const DELETE = `${model}/DELETE`;
const DELETE_SUCCESS = `${model}/DELETE_SUCCESS`;
const DELETE_FAIL = `${model}/DELETE_FAIL`;
const CHANGE = `${model}/CHANGE`;
const CHANGE_FILTER = `${model}/CHANGE_FILTER`;

const API_URL = '/api/v1/firmwares';

export function isLoaded(globalState: IAppState): boolean {
    return globalState.transmitter && globalState.transmitter.dataLoadedSuccess;
}

export function query(options: AxiosRequestConfig): IAction {
    return {
        types: [QUERY, QUERY_SUCCESS, QUERY_FAIL],
        promise: (client: ApiClient<IQueryResult<IYandexDiskResource>>) => client.get(API_URL, options)
    } as IAction;
}

export function get(id: string): IAction {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        promise: (client: ApiClient<IYandexDiskResource>) => client.get(`${API_URL}/${id}`),
        id
    } as IAction;
}

export function remove(id: string): IAction {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: (client: ApiClient<IYandexDiskResource>) => client.delete(`${API_URL}/${id}`)
    } as IAction;
}

export function change(item: IYandexDiskResource): IAction {
    return {
        type: CHANGE,
        result: item,
        id: item.name
    } as IAction;
}

export function changeFilter(filter: any): IAction {
    return {
        type: CHANGE_FILTER,
        result: filter
    } as IAction;
}

export interface IFirmwareState extends IReadProps, IDeleteProps, IQueryProps<IYandexDiskResource> {
    filter: any;
    id: string;
    firmware: IYandexDiskResource;
}

const initialState = {
    filter: {
        limit: 10,
        page: 1
    },
    dataLoadedSuccess: false,
    firmware: {} as IYandexDiskResource
} as IFirmwareState;

interface IRequestAction extends IAction {
    id: number;
}

const defaultAction: IRequestAction = { type: '', result: null, error: null, id: null } as IRequestAction;

export default (state: IFirmwareState = initialState, action: IRequestAction = defaultAction): IFirmwareState => {
    switch (action.type) {
        case QUERY:
            return { ...state, dataLoading: true };
        case QUERY_SUCCESS:
            return { ...state, dataLoading: false, dataLoadedSuccess: true, data: action.result };
        case QUERY_FAIL:
            return { ...state, dataLoading: false, dataLoadedSuccess: false, dataLoadedError: action.error };
        case GET:
            return { ...state, getting: true };
        case GET_SUCCESS:
            return { ...state, getting: false, [action.id]: action.result, getError: '' };
        case GET_FAIL:
            return { ...state, getting: false, [action.id]: null, getError: action.error };
        case DELETE:
            return { ...state, deleting: true };
        case DELETE_SUCCESS:
            return { ...state, deleting: false, deleteSuccess: true };
        case DELETE_FAIL:
            return { ...state, deleting: false, deleteSuccess: false, deleteError: action.error };
        case CHANGE:
            return { ...state, [action.id]: action.result };
        case CHANGE_FILTER:
            return { ...state, filter: action.result };
        default:
            return state;
    }
};
