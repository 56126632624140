import axios from 'axios';
import React from 'react';

export class Preloader extends React.PureComponent<{}, { requestCount: number }> {
    constructor(props: any, context?: any) {
        super(props, context);
        this.state = { requestCount: 0 };
    }

    componentDidMount() {
        axios.interceptors.request.use(config => {
            this.setState({ requestCount: this.state.requestCount + 1 });
            return config;
        });
        axios.interceptors.response.use(
            response => {
                this.setState({ requestCount: this.state.requestCount - 1 });
                return response;
            },
            error => {
                this.setState({ requestCount: this.state.requestCount - 1 });
                return Promise.reject(error);
            }
        );
    }

    render() {
        const { requestCount } = this.state;
        return (
            <div
                className="preloader-container"
                style={{
                    position: 'fixed',
                    width: '100%',
                    height: '4px',
                    zIndex: 100
                }}
            >
                {requestCount > 0 && (
                    <div className="progress" style={{ margin: 0 }}>
                        <div className="indeterminate" />
                    </div>
                )}
            </div>
        );
    }
}
