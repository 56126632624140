// tslint:disable:max-line-length
import { AxiosRequestConfig } from 'axios';
import { IPageFilter } from './../../../interfaces/IPageFilter.d';
import { INotification } from './../../../models/notification.model';
import { ApiClient } from './../../helpers/api-client.helper';
import { IAppState } from './../interfaces/IAppState.d';

const model = `notification`;
const QUERY = `${model}/QUERY`;
const QUERY_SUCCESS = `${model}/QUERY_SUCCESS`;
const QUERY_FAIL = `${model}/QUERY_FAIL`;
const CHANGE_FILTER = `${model}/CHANGE_FILTER`;

const API_URL = '/api/v1/notifications';

export function isLoaded(globalState: IAppState): boolean {
    return globalState.transmitter && globalState.transmitter.dataLoadedSuccess;
}

export function query(options: AxiosRequestConfig): IAction {
    return {
        types: [QUERY, QUERY_SUCCESS, QUERY_FAIL],
        promise: (client: ApiClient<IQueryResult<INotification>>) => client.get(API_URL, options)
    } as IAction;
}

export function changeFilter(filter: any): IAction {
    return {
        type: CHANGE_FILTER,
        result: filter
    } as IAction;
}

export interface INotificationState extends IReadProps, IDeleteProps, IQueryProps<INotification> {
    filter: IPageFilter;
    id: string;
}

const initialState = {
    filter: {
        limit: 10,
        page: 1
    },
    dataLoadedSuccess: false
} as INotificationState;

interface INotificationAction extends IAction {
    id: number;
}

const defaultAction: INotificationAction = { type: '', result: null, error: null, id: null } as INotificationAction;

export default (
    state: INotificationState = initialState,
    action: INotificationAction = defaultAction
): INotificationState => {
    switch (action.type) {
        case QUERY:
            return { ...state, dataLoading: true };
        case QUERY_SUCCESS:
            return { ...state, dataLoading: false, dataLoadedSuccess: true, data: action.result };
        case QUERY_FAIL:
            return { ...state, dataLoading: false, dataLoadedSuccess: false, dataLoadedError: action.error };
        case CHANGE_FILTER:
            return { ...state, filter: action.result };
        default:
            return state;
    }
};
