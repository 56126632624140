import React from 'react';

export class ErrorPage extends React.PureComponent<{}, {}> {
    render() {
        return (
            <section className="app-content">
                <header className="section-header">
                    <h1>Произошла ошибка :(</h1>
                    <a href="/">Перейти на главную страницу</a>
                </header>
            </section>
        );
    }
}
