import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore as _createStore } from 'redux';
// tslint:disable-next-line:no-var-requires
const thunk = require('redux-thunk').default;
import createMiddleware from './middleware/client.middleware';
import reducer from './modules/reducer';

const { NODE_ENV } = process.env;

export default function createStore(history: any, client: any, data?: any): any {
    const isBrowser = typeof window === 'object';

    // Sync dispatched route actions to the history
    const reduxRouterMiddleware = routerMiddleware(history);
    const middlewares = [createMiddleware(client), reduxRouterMiddleware, thunk];
    const composeEnhancers =
        NODE_ENV === 'development' && isBrowser && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose;
    const finalCreateStore = composeEnhancers(applyMiddleware(...middlewares))(_createStore);
    const store = finalCreateStore(reducer, data);

    return store;
}
