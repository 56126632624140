// tslint:disable:max-line-length
import { AxiosRequestConfig } from 'axios';
import { ISubscription } from './../../../models/subscription.model';
import { IUser } from './../../../models/user.model';
import { ApiClient } from './../../helpers/api-client.helper';

const model = `user`;
const QUERY = `${model}/QUERY_LIST`;
const QUERY_SUCCESS = `${model}/LOAD_LIST_SUCCESS`;
const QUERY_FAIL = `${model}/LOAD_LIST_FAIL`;
const GET = `${model}/GET`;
const GET_SUCCESS = `${model}/GET_SUCCESS`;
const GET_FAIL = `${model}/GET_FAIL`;
const UPDATE = `${model}/UPDATE`;
const UPDATE_SUCCESS = `${model}/UPDATE_SUCCESS`;
const UPDATE_FAIL = `${model}/UPDATE_FAIL`;
const SAVE = `${model}/SAVE`;
const SAVE_SUCCESS = `${model}/SAVE_SUCCESS`;
const SAVE_FAIL = `${model}/SAVE_FAIL`;
const SAVE_SUBSCRIPTION = `${model}/SAVE_SUBSCRIPTION`;
const SAVE_SUBSCRIPTION_SUCCESS = `${model}/SAVE_SUBSCRIPTION_SUCCESS`;
const SAVE_SUBSCRIPTION_FAIL = `${model}/SAVE_SUBSCRIPTION_FAIL`;
const DELETE_SUBSCRIPTION = `${model}/DELETE_SUBSCRIPTION`;
const DELETE_SUBSCRIPTION_SUCCESS = `${model}/DELETE_SUBSCRIPTION_SUCCESS`;
const DELETE_SUBSCRIPTION_FAIL = `${model}/DELETE_SUBSCRIPTION_FAIL`;

const API_URL = '/api/v1/users';
const SUBSCRIPTION_API_URL = '/api/v1/subscriptions';

export function query(options: AxiosRequestConfig): IAction {
    return {
        types: [QUERY, QUERY_SUCCESS, QUERY_FAIL],
        promise: (client: ApiClient<ISubscription>) => client.get(API_URL, options)
    } as IAction;
}

export function get(id: string) {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        promise: (client: ApiClient<IUser>) => client.get(`${API_URL}/${id}`),
        id
    };
}

export function update(data: IUser): IAction {
    return {
        types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
        promise: (client: ApiClient<IUser>) => client.put(`${API_URL}/${data.id}`, data),
        id: data.id
    } as IAction;
}

export function save(data: IUser): IAction {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client: ApiClient<IUser>) => client.post(API_URL, data)
    } as IAction;
}

export function saveSubscription(userId: string, subscription: ISubscription): IAction {
    return {
        types: [SAVE_SUBSCRIPTION, SAVE_SUBSCRIPTION_SUCCESS, SAVE_SUBSCRIPTION_FAIL],
        promise: (client: ApiClient<ISubscription>) => client.post(SUBSCRIPTION_API_URL, subscription),
        id: userId
    } as IAction;
}

export function removeSubscription(userId: string, subscriptionId: string): IAction {
    return {
        // tslint:disable-next-line:max-line-length
        types: [DELETE_SUBSCRIPTION, DELETE_SUBSCRIPTION_SUCCESS, DELETE_SUBSCRIPTION_FAIL],
        promise: (client: ApiClient<ISubscription>) => client.delete(`${SUBSCRIPTION_API_URL}/${subscriptionId}`),
        id: userId,
        subscriptionId
    } as any;
}

export interface IUserState extends IReadProps, ICreateProps, IUpdateProps, IQueryProps<IUser> {
    id: string;
    subscriptionId: string;
    filter: any;

    subscriptions: ISubscription[];
    subscriptionSaving: boolean;
    subscriptionSaveSuccess: boolean;
    subscriptionSaveError: string;
    subscriptionDeleting: boolean;
    subscriptionDeleteSuccess: boolean;
    subscriptionDeleteError: string;
}

export interface IUserAction extends IAction {
    id: string;
    subscriptionId: string;
    userFileType: string;
}

const initialState = { dataLoadedSuccess: false } as IUserState;
const defaultAction = { type: '', result: null, error: null, id: null } as IUserAction;

export default (state = initialState, action = defaultAction): IUserState => {
    switch (action.type) {
        case QUERY:
            return { ...state, dataLoading: true };
        case QUERY_SUCCESS:
            return { ...state, dataLoading: false, dataLoadedSuccess: true, data: action.result };
        case QUERY_FAIL:
            return { ...state, dataLoading: false, dataLoadedSuccess: false, dataLoadedError: action.error };
        case GET:
            return { ...state, getting: true };
        case GET_SUCCESS:
            return { ...state, getting: false, [action.id]: action.result, getError: '' };
        case GET_FAIL:
            return { ...state, getting: false, [action.id]: null, getError: action.error };
        case UPDATE:
            return { ...state, updating: true };
        case UPDATE_SUCCESS:
            return { ...state, updating: false, updateSuccess: true, updateError: '', [action.id]: action.result };
        case UPDATE_FAIL:
            return { ...state, updating: false, updateSuccess: false, updateError: action.error };
        case SAVE:
            return { ...state, saving: true };
        case SAVE_SUCCESS:
            return { ...state, saving: false, saveSuccess: true, id: action.result.id, saveError: '' };
        case SAVE_FAIL:
            return { ...state, saving: false, saveSuccess: false, saveError: action.error };

        // Subscription
        case SAVE_SUBSCRIPTION:
            return { ...state, subscriptionSaving: true };
        case SAVE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionSaving: false,
                subscriptionSaveSuccess: true,
                [action.id]: { ...state[action.id], subscriptions: [...state[action.id].subscriptions, action.result] }
            };
        case SAVE_SUBSCRIPTION_FAIL:
            return {
                ...state,
                subscriptionSaving: false,
                subscriptionSaveSuccess: false,
                subscriptionSaveError: action.error
            };
        case DELETE_SUBSCRIPTION:
            return { ...state, subscriptionDeleting: true };
        case DELETE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionDeleting: false,
                subscriptionDeleteSuccess: true,
                [action.id]: {
                    ...state[action.id],
                    subscriptions: state[action.id].subscriptions.filter(s => s.id !== action.subscriptionId)
                }
            };
        case DELETE_SUBSCRIPTION_FAIL:
            return {
                ...state,
                subscriptionDeleting: false,
                subscriptionDeleteSuccess: false,
                subscriptionDeleteError: action.error
            };
        default:
            return state;
    }
};
