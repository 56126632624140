import axios, { AxiosRequestConfig } from 'axios';
import express from 'express';

const isBrowser = typeof window !== 'undefined';

export class ApiClient<T> {
    private _headers: any = {};

    constructor(req?: express.Request) {
        if (!isBrowser && req.isAuthenticated()) {
            this._headers.cookie = req.get('cookie');
        }
    }

    isBrowser(): boolean {
        return isBrowser;
    }

    isServer(): boolean {
        return !isBrowser;
    }

    get(path: string, config: AxiosRequestConfig = {}): Promise<T> {
        return axios
            .get(this._formatUrl(path), { headers: this._headers, ...config })
            .then(r => r.data);
    }

    post(path: string, data?: T, config: any = {}): Promise<T> {
        return axios
            .post(this._formatUrl(path), data, {
                headers: this._headers,
                ...config
            })
            .then(r => r.data);
    }

    put(path: string, data: T, config: any = {}): Promise<T> {
        return axios
            .put(this._formatUrl(path), data, {
                headers: this._headers,
                ...config
            })
            .then(r => r.data);
    }

    delete(path: string, config: any = {}): Promise<any> {
        return axios
            .delete(this._formatUrl(path), {
                headers: this._headers,
                ...config
            })
            .then(r => r.data);
    }

    private _formatUrl(path: string): string {
        const adjustedPath = path[0] !== '/' ? '/' + path : path;
        return (
            (isBrowser
                ? `${location.protocol}//${location.host}`
                : `http://localhost:${process.env.PORT}`) + adjustedPath
        );
    }
}
