import 'materialize-css';
import moment from 'moment';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory, match, Router } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { ReduxAsyncConnect } from 'redux-connect';
import { ApiClient } from './helpers/api-client.helper';
import createStore from './redux/create';
import routes from './routes/routes';
import './styles/style.less';

moment.locale('ru');

const client = new ApiClient();
const store = createStore(browserHistory, client, (window as any).APP_STATE);
const history = syncHistoryWithStore(browserHistory, store);

const { pathname, search, hash } = window.location;
const location = `${pathname}${search}${hash}`;

match({ routes: routes(store), location }, (error, redirectLocation, renderProps) => {
    const component = (
        <Router
            render={props =>
                <ReduxAsyncConnect {...props} helpers={{ client }} filter={item => !item.deferred} /> as any
            }
            history={history}
        >
            {routes(store)}
        </Router>
    );

    hydrate(
        <Provider store={store} key="provider">
            {component}
        </Provider>,
        document.querySelectorAll('[data-ui-role="content"]')[0]
    );
});
