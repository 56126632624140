import { AxiosRequestConfig } from 'axios';
import { IProcess } from './../../../models/process.model';
import { ApiClient } from './../../helpers/api-client.helper';
import { IAppState } from './../interfaces/IAppState.d';

const model = `process`;
const QUERY = `${model}/QUERY`;
const QUERY_SUCCESS = `${model}/QUERY_SUCCESS`;
const QUERY_FAIL = `${model}/QUERY_FAIL`;
const GET = `${model}/GET`;
const GET_SUCCESS = `${model}/GET_SUCCESS`;
const GET_FAIL = `${model}/GET_FAIL`;
const DELETE = `${model}/DELETE`;
const DELETE_SUCCESS = `${model}/DELETE_SUCCESS`;
const DELETE_FAIL = `${model}/DELETE_FAIL`;
const CHANGE = `${model}/CHANGE`;
const CHANGE_FILTER = `${model}/CHANGE_FILTER`;
const SENDTOMAIL = `${model}/SENDTOMAIL`;
const SENDTOMAIL_SUCCESS = `${model}/SENDTOMAIL_SUCCESS`;
const SENDTOMAIL_FAIL = `${model}/SENDTOMAIL_FAIL`;
const SELECT = `${model}/SELECT`;

const API_URL = '/api/v1/processes';

export function isLoaded(globalState: IAppState): boolean {
    return globalState.transmitter && globalState.transmitter.dataLoadedSuccess;
}

export function getSelected(globalState: IAppState): IProcess {
    const { data, selected } = globalState.process;

    return selected && data.items && data.items.length > 0
        ? data.items.find(item => item.id === selected)
        : null;
}

export function query(options: AxiosRequestConfig): IAction {
    return {
        types: [QUERY, QUERY_SUCCESS, QUERY_FAIL],
        promise: (client: ApiClient<IQueryResult<IProcess>>) =>
            client.get(API_URL, options)
    } as IAction;
}

export function get(id: string, options: AxiosRequestConfig = {}): IAction {
    return {
        types: [GET, GET_SUCCESS, GET_FAIL],
        promise: (client: ApiClient<IProcess>) =>
            client.get(`${API_URL}/${id}`, options),
        id
    } as IAction;
}

export function remove(id: string): IAction {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: (client: ApiClient<IProcess>) =>
            client.delete(`${API_URL}/${id}`)
    } as IAction;
}

export function change(item: IProcess): IAction {
    return {
        type: CHANGE,
        result: item,
        id: item.id
    } as IAction;
}

export function changeFilter(filter: any): IAction {
    return {
        type: CHANGE_FILTER,
        result: filter
    } as IAction;
}

export function sendToMail(id: string): IAction {
    return {
        types: [SENDTOMAIL, SENDTOMAIL_SUCCESS, SENDTOMAIL_FAIL],
        promise: (client: ApiClient<IProcess>) =>
            client.post(`${API_URL}/${id}/send-to-mail`)
    } as IAction;
}

export function select(id: string): IAction {
    return {
        type: SELECT,
        result: id
    };
}

export interface IProcessState
    extends IReadProps,
        IDeleteProps,
        IQueryProps<IProcess> {
    filter: any;
    id: string;
    sendingToMail: boolean;
    sendToMailSuccess: boolean;
    sendToMailError: string;
    selected: string;
}

const initialState = {
    filter: {
        limit: 10,
        page: 1
    },
    dataLoadedSuccess: false,
    sendingToMail: false,
    sendToMailSuccess: false,
    sendToMailError: '',
    selected: null
} as IProcessState;

interface IProcessAction extends IAction {
    id: number;
}

const defaultAction: IProcessAction = {
    type: '',
    result: null,
    error: null,
    id: null
} as IProcessAction;

export default (
    state: IProcessState = initialState,
    action: IProcessAction = defaultAction
): IProcessState => {
    switch (action.type) {
        case QUERY:
            return { ...state, dataLoading: true };
        case QUERY_SUCCESS:
            return {
                ...state,
                dataLoading: false,
                dataLoadedSuccess: true,
                data: action.result
            };
        case QUERY_FAIL:
            return {
                ...state,
                dataLoading: false,
                dataLoadedSuccess: false,
                dataLoadedError: action.error
            };
        case GET:
            return { ...state, getting: true };
        case GET_SUCCESS:
            return {
                ...state,
                getting: false,
                [action.id]: action.result,
                getError: ''
            };
        case GET_FAIL:
            return {
                ...state,
                getting: false,
                [action.id]: null,
                getError: action.error
            };
        case DELETE:
            return { ...state, deleting: true };
        case DELETE_SUCCESS:
            return { ...state, deleting: false, deleteSuccess: true };
        case DELETE_FAIL:
            return {
                ...state,
                deleting: false,
                deleteSuccess: false,
                deleteError: action.error
            };
        case CHANGE:
            return { ...state, [action.id]: action.result };
        case CHANGE_FILTER:
            return { ...state, filter: action.result };
        case SENDTOMAIL:
            return { ...state, sendingToMail: true };
        case SENDTOMAIL_SUCCESS:
            return { ...state, sendingToMail: false, sendToMailSuccess: true };
        case SENDTOMAIL_FAIL:
            return {
                ...state,
                sendingToMail: false,
                sendToMailSuccess: false,
                sendToMailError: action.error
            };
        case SELECT:
            return { ...state, selected: action.result };
        default:
            return state;
    }
};
