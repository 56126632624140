import { IUser } from './../../../models/user.model';
import { ApiClient } from './../../helpers/api-client.helper';
import { IAppState } from './../interfaces/IAppState.d';

const model = `auth`;
const LOAD = `${model}/LOAD`;
const LOAD_SUCCESS = `${model}/LOAD_SUCCESS`;
const LOAD_FAIL = `${model}/LOAD_FAIL`;
const LOGIN = `${model}/LOGIN`;
const LOGIN_SUCCESS = `${model}/LOGIN_SUCCESS`;
const LOGIN_FAIL = `${model}/LOGIN_FAIL`;
const LOGOUT = `${model}/LOGOUT`;
const LOGOUT_SUCCESS = `${model}/LOGOUT_SUCCESS`;
const LOGOUT_FAIL = `${model}/LOGOUT_FAIL`;
const REGISTER = `${model}/REGISTER`;
const REGISTER_SUCCESS = `${model}/REGISTER_SUCCESS`;
const REGISTER_FAIL = `${model}/REGISTER_FAIL`;
const FORGOT = `${model}/FORGOT`;
const FORGOT_SUCCESS = `${model}/FORGOT_SUCCESS`;
const FORGOT_FAIL = `${model}/FORGOT_FAIL`;

const url = '/api/v1/auth';

export function isLoaded(globalState: IAppState): boolean {
    return globalState.auth && globalState.auth.loaded;
}

export function load(): IAction {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client: ApiClient<IUser>) => client.get(`${url}/token`)
    } as IAction;
}

export function login(data: IUser): IAction {
    return {
        types: [LOGIN, LOGIN_SUCCESS, LOGIN_FAIL],
        promise: (client: ApiClient<IUser>) => client.post(`${url}/login`, data)
    } as IAction;
}

export function logout(): IAction {
    return {
        types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
        promise: (client: ApiClient<IUser>) => client.post(`${url}/logout`)
    } as IAction;
}

export function register(data: IUser): IAction {
    return {
        types: [REGISTER, REGISTER_SUCCESS, REGISTER_FAIL],
        promise: (client: ApiClient<IUser>) => client.post(`${url}/register`, data)
    } as IAction;
}

export function forgot(data: IUser): IAction {
    return {
        types: [FORGOT, FORGOT_SUCCESS, FORGOT_FAIL],
        promise: (client: ApiClient<IUser>) => client.post(`${url}/forgot`, data)
    } as IAction;
}

export interface IAuthState {
    user: IUser;

    loading: boolean;
    loaded: boolean;
    loadError: string;

    loggingIn: boolean;
    loginSuccess: boolean;
    loginError: string;

    loggingOut: boolean;
    logoutSuccess: boolean;
    logoutError: string;

    registering: boolean;
    registerSuccess: boolean;
    registerError: string;

    forgoting: boolean;
    forgotSuccess: boolean;
    forgotError: string;
}

const initialState = {
    loaded: false
} as IAuthState;

const defaultAction: IAction = { type: '', result: null, error: null } as IAction;

export default (state: IAuthState = initialState, action: IAction = defaultAction): IAuthState => {
    switch (action.type) {
        case LOAD:
            return { ...state, loading: true };
        case LOAD_SUCCESS:
            return { ...state, loading: false, loaded: true, user: action.result };
        case LOAD_FAIL:
            return { ...state, loading: false, loaded: false, loadError: action.error };
        case LOGIN:
            return { ...state, loggingIn: true };
        case LOGIN_SUCCESS:
            return { ...state, loggingIn: false, loginSuccess: true, loginError: '', user: action.result };
        case LOGIN_FAIL:
            return { ...state, loggingIn: false, loginSuccess: false, loginError: action.error, user: null };
        case LOGOUT:
            return { ...state, loggingOut: true };
        case LOGOUT_SUCCESS:
            return { ...state, loggingOut: false, logoutSuccess: true, logoutError: '', user: null };
        case LOGOUT_FAIL:
            return { ...state, loggingOut: false, logoutSuccess: false, logoutError: action.error };
        case REGISTER:
            return { ...state, registering: true };
        case REGISTER_SUCCESS:
            return { ...state, registering: false, registerSuccess: true, registerError: '' };
        case REGISTER_FAIL:
            return { ...state, registering: false, registerSuccess: false, registerError: action.error };
        case FORGOT:
            return { ...state, forgoting: true };
        case FORGOT_SUCCESS:
            return { ...state, forgoting: false, forgotSuccess: true, forgotError: '' };
        case FORGOT_FAIL:
            return { ...state, forgoting: false, forgotSuccess: false, forgotError: action.error };
        default:
            return state;
    }
};
