import React from 'react';
import io from 'socket.io-client';

export class WebsocketComponent extends React.PureComponent<{}, {}> {
    private _socket: SocketIOClient.Socket;

    componentDidMount() {
        const path = `${window.location.protocol}//${window.location.host}`;
        this._socket = io(path);
        this._socket.on('connect', this._onConnect);
        this._socket.on('disconnect', this._onDisconnect);
        this._socket.on('user', this._onUser);
    }

    componentWillUnmount() {
        this._socket.off('connect', this._onConnect);
        this._socket.off('disconnect', this._onDisconnect);
        this._socket.off('user', this._onUser);
    }

    render() {
        return null;
    }

    private _onConnect() {
        document.dispatchEvent(new CustomEvent('websocket-event', { detail: 'connect' }));
    }
    private _onDisconnect() {
        document.dispatchEvent(new CustomEvent('websocket-event', { detail: 'disconnect' }));
    }
    private _onUser(data: any) {
        document.dispatchEvent(new CustomEvent('websocket-message', { detail: data }));
    }
}
