// tslint:disable:max-line-length
import { AxiosRequestConfig } from 'axios';
import { IYaToken } from './../../../models/ya-token.model';

const model = `oAuth`;
const LIST = `${model}/LIST`;
const LIST_SUCCESS = `${model}/LIST_SUCCESS`;
const LIST_FAIL = `${model}/LIST_FAIL`;
const DELETE = `${model}/DELETE`;
const DELETE_SUCCESS = `${model}/DELETE_SUCCESS`;
const DELETE_FAIL = `${model}/DELETE_FAIL`;
const GET_AUTHORIZE_LINK = `${model}/GET_AUTHORIZE_LINK`;
const GET_AUTHORIZE_LINK_SUCCESS = `${model}/GET_AUTHORIZE_LINK_SUCCESS`;
const GET_AUTHORIZE_LINK_FAIL = `${model}/GET_AUTHORIZE_LINK_FAIL`;

const API_URL = '/api/v1/o-auth';

export function getList(options?: AxiosRequestConfig): IAction {
    return {
        types: [LIST, LIST_SUCCESS, LIST_FAIL],
        promise: client => {
            return client.get(`${API_URL}/all`, options);
        }
    } as IAction;
}
export function remove(id: string): IAction {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: client => client.delete(`${API_URL}/${id}`)
    } as IAction;
}
export function getAuthorizeLink(options?: AxiosRequestConfig): IAction {
    return {
        types: [GET_AUTHORIZE_LINK, GET_AUTHORIZE_LINK_SUCCESS, GET_AUTHORIZE_LINK_FAIL],
        promise: client => {
            return client.get(`${API_URL}/authorize-link`, options);
        }
    } as IAction;
}

export interface IYandexOAuthState extends IDeleteProps, IListProps<IYaToken> {
    authorizeLink: { authorize: string };
    authorizeLinkLoading: boolean;
    authorizeLinkLoaded: boolean;
    authorizeLinkError: string;
}

const initialState = { list: [], listLoading: false } as IYandexOAuthState;
const defaultAction = { type: '', result: null, error: null, id: null } as IAction;

export default (state = initialState, action = defaultAction): IYandexOAuthState => {
    switch (action.type) {
        case LIST:
            return { ...state, listLoading: true };
        case LIST_SUCCESS:
            return { ...state, listLoading: false, listLoadSuccess: true, list: action.result };
        case LIST_FAIL:
            return { ...state, listLoading: false, listLoadSuccess: false, listLoadError: action.error };
        case DELETE:
            return { ...state, deleting: true };
        case DELETE_SUCCESS:
            return { ...state, deleting: false, deleteSuccess: true };
        case DELETE_FAIL:
            return { ...state, deleting: false, deleteSuccess: false, deleteError: action.error };
        case GET_AUTHORIZE_LINK:
            return { ...state, authorizeLinkLoading: true };
        case GET_AUTHORIZE_LINK_SUCCESS:
            return { ...state, authorizeLinkLoading: false, authorizeLinkLoaded: true, authorizeLink: action.result };
        case GET_AUTHORIZE_LINK_FAIL:
            return {
                ...state,
                authorizeLinkLoading: false,
                authorizeLinkLoaded: false,
                authorizeLinkError: action.error
            };
        default:
            return state;
    }
};
