import React from 'react';

export class NoMatchPage extends React.Component<{}, {}> {
    render() {
        return (
            <section className="app-content">
                <header className="section-header">
                    <h1>Страница не найдена :(</h1>
                    <a href="/">Перейти на главную страницу</a>
                </header>
            </section>
        );
    }
}
