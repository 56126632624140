import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { asyncConnect } from 'redux-connect';
import { IOwnProps } from './../interfaces/IOwnProps';
import { IUser } from './../models/user.model';
import { Preloader } from './components/preloader/preloader.component';
import { WebsocketComponent } from './components/websocket/websocket.component';
import { IAppState } from './redux/interfaces/IAppState';
import * as authActions from './redux/modules/auth.module';
import { loggerService } from './services/logger.service';

const { NODE_ENV } = process.env;
const isDevelopment = ['development', 'test'].indexOf(NODE_ENV) > -1;

interface IAppStateProps {
    auth: IUser;
    loggingIn: boolean;
    loginSuccess: boolean;
    loggingOut: boolean;
    logoutSuccess: boolean;
    logoutError: string;
}

@asyncConnect([
    {
        promise: ({ store: { dispatch, getState } }) => {
            const promises = [];
            if (!authActions.isLoaded(getState())) {
                promises.push(dispatch(authActions.load()));
            }
            return Promise.all(promises);
        }
    }
])
class App extends React.Component<IAppStateProps & IDispatchProps & IOwnProps, {}> {
    componentWillReceiveProps(nextProps: IAppStateProps) {
        const { dispatch } = this.props;
        if (this.props.loggingOut) {
            if (nextProps.logoutSuccess) {
                (M as any).toast({ html: 'Вы успешно вышли' }, 4000);
                dispatch(push('/auth/login'));
            } else if (nextProps.logoutError) {
                (M as any).toast({ html: nextProps.logoutError, displayLength: 60000, classes: 'red lighten-1' });
            }
        }
        if (this.props.loggingIn) {
            if (nextProps.loginSuccess) {
                this._authenticate(nextProps.auth);
                (M as any).toast({ html: 'Вы успешно авторизовались' }, 4000);
            }
        }
    }

    componentDidMount() {
        if (this.props.auth && this.props.auth.id) {
            this._authenticate(this.props.auth);
        }
    }

    render() {
        return (
            <div>
                <Helmet
                    defaultTitle="TmServer — Мониторинг передатчиков в реальном времени"
                    titleTemplate="TmServer — %s"
                />
                <Preloader />
                <WebsocketComponent />
                {this.props.children}
            </div>
        );
    }

    private _authenticate(auth: IUser): void {
        if (!isDevelopment) {
            loggerService.configure({
                payload: {
                    person: {
                        id: auth.id,
                        username: auth.telephone
                    }
                }
            });
        }
    }
}

export default connect<IAppStateProps, IDispatchProps, IOwnProps>((state: IAppState) => ({
    auth: state.auth.user,
    loggingIn: state.auth.loggingIn,
    loginSuccess: state.auth.loginSuccess,
    loggingOut: state.auth.loggingOut,
    logoutSuccess: state.auth.logoutSuccess,
    logoutError: state.auth.logoutError
}))(App);
