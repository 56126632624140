export default function clientMiddleware(client: any): any {
    return ({ dispatch, getState }) => {
        return next => action => {
            if (typeof action === 'function') {
                return action(dispatch, getState);
            }

            const { promise, types, ...rest } = action;
            if (!promise) {
                return next(action);
            }

            const [REQUEST, SUCCESS, FAILURE] = types;
            next({ ...rest, type: REQUEST });

            const actionPromise = promise(client);
            actionPromise
                .then(
                    result => next({ ...rest, result, type: SUCCESS }),
                    error =>
                        next({
                            ...rest,
                            type: FAILURE,
                            error:
                                (error && error.response && error.response.data && error.response.data.message) ||
                                (error && error.message) ||
                                'Ошибка'
                        })
                )
                .catch(error => {
                    console.error('MIDDLEWARE ERROR:', error);
                    next({ ...rest, error, type: FAILURE });
                });

            return actionPromise;
        };
    };
}
