import Rollbar from 'rollbar';

const { NODE_ENV } = process.env;
const isDevelopment = ['development', 'test'].indexOf(NODE_ENV) > -1;

// tslint:disable-next-line:no-var-requires
const config = require('./../config/clientConfig.json');

export const loggerService = Rollbar.init({
    accessToken: config.rollbar.post_client_item,
    payload: { environment: NODE_ENV },
    enabled: !isDevelopment
});
