import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import { ReducersMapObject } from 'redux';
import { reducer as reduxAsyncConnect } from 'redux-connect';
import process from './../modules/process.module';
import auth from './auth.module';
import firmware from './firmware.module';
import monitoringInformation from './monitoring-information.module';
import notification from './notification.module';
import oAuth from './o-auth.module';
import request from './request.module';
import transmitter from './transmitter.module';
import user from './user.module';

export default combineReducers({
    routing: routerReducer,
    reduxAsyncConnect,
    auth,
    firmware,
    monitoringInformation,
    notification,
    oAuth,
    process,
    request,
    transmitter,
    user
} as ReducersMapObject);
